<template>
    <div class="grid">
        <div class="col-12">
            <div>
                <h5 class="titulo_pedidos">Integrações</h5>
                <a href="/cadastrar-integracao">
                <Button label="Cadastrar Integração" class="p-button-primary btn_cadastrar"/> 
                </a>
            </div>
            <Loading v-if="loading" />

            <div class="grid">
                <div v-for="(marketplace, index) in marketplaces" :key="index" class="col-3">
                    <div class="card">
                        <div>
                            <a :href="'/marketplace/' + marketplace.id">
                                <img :src="marketplace.imagem" class="img_marketplace" :class="{ 'img_marketplace_preto_branco': marketplace.integracao.length == 0 }" /> 
                                <div class="nome_marketplace">{{ marketplace.nome }}</div>
                                <div style="clear:both"></div>
                            </a>
                        </div>
                        <div v-if="marketplace.integracao.length != 0">
                            <button v-if="marketplace.integracao[0].testada == 'NAO'" @click="testarIntegracao(marketplace.id, index)" class="btn_transparente">
                                <i class="pi pi-clock" style="color:#F5C518" title="Aguardando Teste" ></i>
                            </button>
                            <i class="pi pi-check" style="font-size: 1rem;color: #077D55;" title="Integração OK!" v-else></i>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
        <Dialog header="Efetuando teste de Integração" v-model:visible="mostrarModalTeste" >
            <div style="width: 100%; text-align: center;">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
        </Dialog>
        
        <Toast position="bottom-left" />
</template>

<script>
import Api from '../service/api';
import Loading from '../components/Loading.vue';
export default {
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id:0,
            api: null,
            loading: true,
            mostrarAdicionar: false,
            marketplaces:[],
            empresaId: "1",
            origemSelecao: null,
            displayAnuncio: false,
            mostrarModalTeste:false,
            origem: [
                { nome: "0 - Nacional, exceto as indicadas nos códigos 3 a 5" }
            ],
            tipoSelecao: null,
            tipo: [
                "Interno",
                "Simples"
            ],
            tipoEmbalagem: null,
            embalagem: [
                { nome: "Pacote/Caixa" }
            ],
            embalagemCustomizada: null,
            customizada: [
                { nome: "Embalagem Customizada" }
            ],
            valorSimNao: null,
            simNao: [
                { nome: "Sim" },
                { nome: "Não" }
            ]
        };
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
    },
    mounted() {
        this.listarMarketplaces();
    },
    methods: {/*
        listarMarketplaces() {
        //    this.loading = true;
            this.api.listarIntegracoes(this.token,this.empresa_id).then(data => {
                this.marketplaces = data.data
                this.loading = false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },*/
        
        testarIntegracao(marketplace_id, index) {
            this.mostrarModalTeste = true;

            this.api.testeIntegracao({ token: this.token, empresa_id: this.empresa_id, marketplace_id }).then(data => {
                if (!data.data.verifica_erro) {
                    this.marketplaces[index].integracao[0].testada = 'SIM';
                    this.$toast.add({ severity: "success", summary: "Sucesso", detail: "Integração testada com sucesso!", life: 3000 });
                } else {
                    alert("Erro ao fazer o teste de integração");
                }

            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            }).finally(() => {
                this.mostrarModalTeste = false;
            });

        },
        listarMarketplaces() {
            //    this.loading = true;
            this.api.listarMarketplacesIntegracao({token: this.token, empresa_id: this.empresa_id }).then(data => {
                this.marketplaces = data.data.resposta
                this.loading = false;
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            });
        },
        cadastrarProduto() {
            if(this.nome !== "")
            this.api.criarAnuncio(this.empresaId)
            
            this.tipoCadastro = ""
            window.alert("Cadastro Efetuado")

        }
    },
    components: { Loading }
}
</script>
<style scoped>
button{
    margin-top: 0;
}
.inputContainer {
    display: flex;
    flex-direction: column;
}

.inputGroup {
    margin-top: 25px;
}

.inputGroup div {
    margin: 5px;
}
.card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #F5C518;
}
hr {
    border: 1px solid #00000040;
}

input{
    margin: 10px;
}
.btn_cadastrar{
    margin-bottom:20px;
    background-color: #4e87ee;
    border: none;
}
.titulo_pedidos{ 
    width: 50%;
}
.marketplace{
    border:#f3f3f3 solid 1px;
    padding:15px;
}
.img_marketplace{
    float: left;
    border-radius: 50%;
    width:40px;
    height:40px;
    border: #ccc solid 1px;
    padding:5px;
    margin-right:5px;
    margin-top:-5px;
}


.img_marketplace_preto_branco {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE */
}

.btn_transparente{
    border:none;
    background: none;
    cursor: pointer;
}

.nome_marketplace{
    margin-top:3px;
    float: left;
    font-size: 14pt;
    font-weight: bold;
    color:#485057;
}
</style>